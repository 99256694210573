/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes RadialBadge_9_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_9.Badge--animated .DOTS
{
    animation: RadialBadge_9_Animation 40s linear infinite;
    transform-origin: center;
}

.RadialBadge_9.Badge--animated .DOTS2
{
    animation: RadialBadge_9_Animation 60s linear infinite;
    transform-origin: center;
}

.RadialBadge_9
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_9:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_9 .Badge__FirstFillColor
{
    fill: #000000;
}
.RadialBadge_9 .Badge__FirstStrokeColor
{
    stroke: #000000;
}

.RadialBadge_9 .Badge__SecondFillColor
{
    fill: #f1b41e;
}

.RadialBadge_9 .Badge__SecondStrokeColor
{
    stroke: #f1b41e;
}

.RadialBadge_9 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_9 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}