/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes RadialBadge_4_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_4.Badge--animated .PIZZA
{
    transition: .2s transform ease-out;
    animation: RadialBadge_4_Animation 40s linear infinite;
    transform-origin: center;
}

.RadialBadge_4
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_4:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_4 .Badge__FirstFillColor
{
    fill: #f97b7e;
}
.RadialBadge_4 .Badge__FirstStrokeColor
{
    stroke: #f97b7e;
}

.RadialBadge_4 .Badge__SecondFillColor
{
    fill: #4d4d4d;
}

.RadialBadge_4 .Badge__SecondStrokeColor
{
    stroke: #4d4d4d;
}

.RadialBadge_4 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_4 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}