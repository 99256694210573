/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_2
{
    user-select: none;
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_2 .Badge__FirstFillColor
{
    fill: #000000;
}
.BlankBadge_2 .Badge__FirstStrokeColor
{
    stroke: #000000;
}

.BlankBadge_2 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.BlankBadge_2 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.BlankBadge_2 .Badge__ThirdFillColor
{
    fill: #dd2222;
}

.BlankBadge_2 .Badge__ThirdStrokeColor
{
    stroke: #dd2222;
}