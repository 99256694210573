/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes RadialBadge_2_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_2.Badge--animated .RADIAL_LINE_DOTS
{
    transition: .2s transform ease-out;
    animation: RadialBadge_2_Animation 40s linear infinite;
    transform-origin: center;
}

.RadialBadge_2
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_2:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_2 .Badge__FirstFillColor
{
    fill: #3e5266;
}
.RadialBadge_2 .Badge__FirstStrokeColor
{
    stroke: #3e5266;
}

.RadialBadge_2 .Badge__SecondFillColor
{
    fill: #59d9e4;
}

.RadialBadge_2 .Badge__SecondStrokeColor
{
    stroke: #59d9e4;
}

.RadialBadge_2 .Badge__ThirdFillColor
{
    fill: #d6e0e8;
}

.RadialBadge_2 .Badge__ThirdStrokeColor
{
    stroke: #d6e0e8;
}