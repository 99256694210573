/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes BurgerBadge_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.BurgerBadge.Badge--animated .SUNBURST
{
    animation: BurgerBadge_Animation 40s linear infinite;
    transform-origin: center;
}

.BurgerBadge
{
    user-select: none;

    transition: .2s transform ease-out;
}

.BurgerBadge:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BurgerBadge .Badge__FirstFillColor
{
    fill: #f9d974;
}
.BurgerBadge .Badge__FirstStrokeColor
{
    stroke: #f9d974;
}

.BurgerBadge .Badge__SecondFillColor
{
    fill: #af202d;
}

.BurgerBadge .Badge__SecondStrokeColor
{
    stroke: #af202d;
}

.BurgerBadge .Badge__ThirdFillColor
{
    fill: #e02e31;
}

.BurgerBadge .Badge__ThirdStrokeColor
{
    stroke: #e02e31;
}