/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_6
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_6:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_6 .Badge__FirstFillColor
{
    fill: #161516;
}
.RadialBadge_6 .Badge__FirstStrokeColor
{
    stroke: #161516;
}

.RadialBadge_6 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.RadialBadge_6 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_6 .Badge__ThirdFillColor
{
    fill: #ccc;
}

.RadialBadge_6 .Badge__ThirdStrokeColor
{
    stroke: #ccc;
}