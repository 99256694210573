/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes RadialBadge_5_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_5.Badge--animated .OUTLINE
{
    transition: .2s transform ease-out;
    animation: RadialBadge_5_Animation 40s linear infinite;
    transform-origin: center;
}

.RadialBadge_5
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_5:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_5 .Badge__FirstFillColor
{
    fill: #ffffff;
}
.RadialBadge_5 .Badge__FirstStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_5 .Badge__SecondFillColor
{
    fill: #2952b7;
}

.RadialBadge_5 .Badge__SecondStrokeColor
{
    stroke: #2952b7;
}

.RadialBadge_5 .Badge__ThirdFillColor
{
    fill: #d6e0e8;
}

.RadialBadge_5 .Badge__ThirdStrokeColor
{
    stroke: #d6e0e8;
}