/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes SimpleBadgeSwing
{
    0%{transform:rotate(-2deg) scale(0.9)}
    50%{transform:rotate(2deg) scale(0.9)}
    100%{transform:rotate(-2deg) scale(0.9)}
}

.SimpleBadge
{
    user-select: none;
}

.SimpleBadge.Badge--animated
{
    transition: .2s transform ease-out;
    animation: SimpleBadgeSwing 3s infinite ease-in-out;
    transform-origin: top;
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.SimpleBadge .Badge__FirstFillColor
{
    fill: #ff2e2e;
}
.SimpleBadge .Badge__FirstStrokeColor
{
    stroke: #ff2e2e;
}

.SimpleBadge .Badge__SecondFillColor
{
    fill: #ffffff;
}

.SimpleBadge .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.SimpleBadge .Badge__ThirdFillColor
{
    fill: #dd2222;
}

.SimpleBadge .Badge__ThirdStrokeColor
{
    stroke: #dd2222;
}