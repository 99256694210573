/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_8
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_8:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_8 .Badge__FirstFillColor
{
    fill: #bbecf8;
}
.RadialBadge_8 .Badge__FirstStrokeColor
{
    stroke: #bbecf8;
}

.RadialBadge_8 .Badge__SecondFillColor
{
    fill: #0a8aae;
}

.RadialBadge_8 .Badge__SecondStrokeColor
{
    stroke: #0a8aae;
}

.RadialBadge_8 .Badge__ThirdFillColor
{
    fill: #d33068;
}

.RadialBadge_8 .Badge__ThirdStrokeColor
{
    stroke: #d33068;
}