@font-face
{
  font-family: 'GT Pressura Regular';
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face
{
  font-family: 'GT Pressura Bold';
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* ------------------------------------------------------------------------------------------------------------------ */

*
{
  margin: 0;
  padding: 0;
}

img
{
  width: 100%;
  height: auto;
}

.logo
{
  margin: 0 auto 2vw auto;
  max-width: 100px;
}

.wix
{
  display: inline-block;
  max-width: 5vw;
}

strong
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
}

@media only screen and (max-width: 900px)
{
  .wix
  {
    max-width: 7.5vw;
  }

  .hide_on_mobile
  {
    display: none !important;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#about,
#benefits,
#testimonials,
#features,
#faq,
#marketplace,
#footer,
#cta
{
  font-family: 'GT Pressura Regular', sans-serif;
  font-weight: normal;
  font-size: 1.8vw;
  line-height: 2.2vw;
  color: #334854;
}

@media only screen and (max-width: 900px)
{
  #about,
  #benefits,
  #testimonials,
  #features,
  #faq,
  #marketplace,
  #footer,
  #cta
  {
    font-size: 16px;
    line-height: 22px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

h1,
h4
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 11vw;
  line-height: 9vw;
  text-transform: uppercase;
}

h1 span,
h4 span
{
  display: block;

  font-family: 'GT Pressura Regular', sans-serif;
  font-size: 2vw;
  line-height: 5vw;
}

h2
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 1.8vw;/*2vw;*/
  line-height: 3vw;
  text-transform: uppercase;
}

h3
{
  font-size: 3vw;
  line-height: 3.4vw;
}

@media only screen and (max-width: 900px)
{
  h1,
  h4
  {
    font-size: 20vw;
    line-height: 16vw;
    text-align: center;
  }

  h1 span,
  h4 span
  {
    font-size: 3.8vw;
    line-height: 9vw;
  }

  h2
  {
    font-size: 25px;
    line-height: 30px;
  }

  h3
  {
    margin-right: auto;
    margin-left: auto;
    width: 80%;

    font-size: 25px;
    line-height: 30px;
  }

  h3 br
  {
    display: none;
  }
}

@media only screen and (max-width: 900px)
{
  h3
  {
    width: 100%;
  }
}

p a
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: #334854;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.columns
{
  position: relative;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* ---------------------- */

.columns.w20 .column
{
  margin-top: 5vw;
  width: 20%;

  text-align: center;
}

.columns.w20 .column:nth-child(1),
.columns.w20 .column:nth-child(2),
.columns.w20 .column:nth-child(3),
.columns.w20 .column:nth-child(4),
.columns.w20 .column:nth-child(5)
{
  margin-top: 0;
}

.columns.w20 .column .inner
{
  margin-right: 3vw;
  margin-left: 3vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w20 .column
  {

  }

  .columns.w20 .column:nth-child(1),
  .columns.w20 .column:nth-child(2),
  .columns.w20 .column:nth-child(3),
  .columns.w20 .column:nth-child(4),
  .columns.w20 .column:nth-child(5)
  {

  }

  .columns.w20 .column .inner
  {

  }
}

/* ---------------------- */

.columns.w25 .column
{
  margin-top: 5vw;
  width: 25%;

  text-align: center;
}

.columns.w25 .column:nth-child(1),
.columns.w25 .column:nth-child(2),
.columns.w25 .column:nth-child(3),
.columns.w25 .column:nth-child(4)
{
  margin-top: 0;
}

.columns.w25 .column .inner
{
  margin-right: 3vw;
  margin-left: 3vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w25 .column
  {
    margin-top: 50px;
    width: 33.33%;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3),
  .columns.w25 .column:nth-child(4)
  {
    margin-top: 50px;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3)
  {
    margin-top: 0;
  }

  .columns.w25 .column .inner
  {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 700px)
{
  .columns.w25 .column
  {
    margin-top: 30px;
    width: 50%;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3),
  .columns.w25 .column:nth-child(4)
  {
    margin-top: 30px;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2)
  {
    margin-top: 0;
  }
}

/* ---------------------- */

.columns.w50 .column
{
  width: 50%;
}

@media only screen and (max-width: 900px)
{
  .columns.w50 .column
  {
    width: 100%;
  }

  .columns.w50 .column.right
  {
    margin-top: 5vw;
  }
}

/* ---------------------- */

.columns.w33 .column
{
  margin-top: 2vw;
  width: 33.33%;
}

.columns.w33 .column .inner
{
  padding-top: 2vw;
  border-top: 1px solid #334854;
}

.columns.w33 .column:nth-child(1),
.columns.w33 .column:nth-child(2),
.columns.w33 .column:nth-child(3)
{
  margin-top: 0;
}

.columns.w33 .column:nth-child(3n+1) .inner
{
  margin-right: 2vw;
}

.columns.w33 .column:nth-child(3n+2) .inner
{
  margin-right: 1vw;
  margin-left: 1vw;
}

.columns.w33 .column:nth-child(3n+3) .inner
{
  margin-left: 2vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w33 .column
  {
    margin-top: 20px;
    width: 50%;
  }

  .columns.w33 .column .inner
  {
    padding-top: 20px;
  }

  .columns.w33 .column:nth-child(3)
  {
    margin-top: 20px;
  }

  .columns.w33 .column:nth-child(3n+1) .inner,
  .columns.w33 .column:nth-child(3n+2) .inner,
  .columns.w33 .column:nth-child(3n+3) .inner
  {
    margin: 0;
  }

  .columns.w33 .column:nth-child(2n+1) .inner
  {
    margin-right: 20px;
  }

  .columns.w33 .column:nth-child(2n+2) .inner
  {
    margin-right: 0;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 700px)
{
  .columns.w33 .column
  {
    margin-top: 20px;
    width: 100%;
  }

  .columns.w33 .column .inner
  {
    padding-top: 20px;
  }

  .columns.w33 .column:nth-child(1),
  .columns.w33 .column:nth-child(2),
  .columns.w33 .column:nth-child(3)
  {
    margin-top: 20px;
  }

  .columns.w33 .column:nth-child(3n+1) .inner,
  .columns.w33 .column:nth-child(3n+2) .inner,
  .columns.w33 .column:nth-child(3n+3) .inner
  {
    margin: 0;
  }

  .columns.w33 .column:nth-child(2n+1) .inner
  {
    margin-right: 0;
  }

  .columns.w33 .column:nth-child(2n+2) .inner
  {
    margin-right: 0;
    margin-left: 0;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

.inside
{
  position: relative;
  padding: 7vw 18vw;
}

.icon
{
  font-size: 10vw;
  line-height: 5vw;
}

@media only screen and (max-width: 900px)
{
  .inside
  {
    padding: 10vw;
  }

  .icon
  {
    font-size: 10vw;
    line-height: 5vw;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

@-webkit-keyframes SimpleBadgeSwingWebsite
{
  0% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }

  50% {
    -webkit-transform: rotate(2deg) scale(0.9);
    transform: rotate(2deg) scale(0.9);
  }

  100% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }
}

@keyframes SimpleBadgeSwingWebsite
{
  0% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }

  50% {
    -webkit-transform: rotate(2deg) scale(0.9);
    transform: rotate(2deg) scale(0.9);
  }

  100% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }
}

#staticbadge
{
  cursor: pointer;
  z-index: 0;
  position: absolute;
  bottom: 3vw;
  right: 5vw;
  width: 16vw;

  -webkit-transition: .2s transform ease-out;
  transition: .2s transform ease-out;

  -webkit-animation: SimpleBadgeSwingWebsite 3s infinite ease-in-out;
  animation: SimpleBadgeSwingWebsite 3s infinite ease-in-out;

  -webkit-transform-origin: top;
  transform-origin: top;
}

@media only screen and (max-width: 900px)
{
  #staticbadge
  {
    display: none;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#about
{
  background-color: #faf85d;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#video
{
  background-color: #faf85d;

  line-height: 0;
}

#video video
{
  width: 100%;
  height: auto;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#benefits
{
  background-color: #faf85d;
}

#benefits .headline
{
  margin-bottom: 5vw;

  text-align: center;
}

@media only screen and (max-width: 900px)
{
  #benefits .headline
  {
    margin-bottom: 30px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#badges
{
  background-color: #faf85d;
}

#badges .inside
{
  padding: 3vw;
  margin: 0 6vw;
  background-color: #d1dfe1;
}

#badges .border
{
  padding: 6vw;
  border: 1px solid #334854;
}

#badges .headline
{
  margin-bottom: 5vw;
  text-align: center;
}

#badges .headline h3
{
  margin-bottom: 2vw;
  font-family: 'GT Pressura Regular', sans-serif;
  color: #334854;
}

@media only screen and (max-width: 900px)
{
 #badges .inside
 {
   margin: 0;
 }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#testimonials
{
  background-color: #faf85d;

  text-align: center;
}

#testimonials h3
{
  margin-bottom: 2vw;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#features
{
  background-color: #faf85d;
}

#features .inside
{
  padding-top: 0;
}

@media only screen and (max-width: 900px)
{
  #features
  {
    display: none;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#faq
{
  background-color: #334854;

  color: #d1dfe1;
}

#faq .headline
{
  margin-bottom: 5vw;

  text-align: center;
}

#faq strong
{
  display: block;
  margin-bottom: 0.5vw;

  color: #ffffff;
}

#faq .item
{
  padding: 2vw 0;
  border-bottom: 1px solid #d1dfe1;
}

#faq .item:nth-child(2)
{
  padding-top: 0;
}

#faq .item:last-child
{
  padding-bottom: 0;
  border: none;
}

@media only screen and (max-width: 900px)
{
  #faq .headline
  {
    margin-bottom: 30px;
  }

  #faq strong
  {
    margin-bottom: 10px;
  }

  #faq .item
  {
    padding: 20px 0;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#marketplace
{
  background-color: #d1dfe1;/*#00c638;*/
}

#marketplace .inside
{
  padding-bottom: 14vw;
}

@media only screen and (max-width: 900px)
{
  #marketplace .inside
  {
    padding-bottom: 30vw;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#footer
{
  display: none;
  background-color: #334854;

  font-size: 1vw;
  line-height: 1vw;
  color: #d1dfe1;
}

#footer .inside
{
  padding: 2.5vw 3vw;
  padding-bottom: 8.5vw;
}

#footer p a
{
  font-family: 'GT Pressura Regular', sans-serif;
  text-transform: none;
  color: #d1dfe1;
}

#footer .column.right
{
  text-align: right;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#cta
{
  cursor: pointer;
  z-index: 10000;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #00c638;

  opacity: .95;

  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 3vw;
  text-transform: uppercase;
  text-align: center;
  color: #005117;
}

#cta a
{
  display: block;
  padding: 3vw 2vw;

  text-decoration: none;
  color: #005117;
}

@media only screen and (min-width: 1100px)
{
  #cta,
  #cta a
  {
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  #cta:hover
  {
    opacity: 1;
  }

  #cta a:hover
  {
    padding: 4vw 2vw;
  }
}

@media only screen and (max-width: 900px)
{
  #cta
  {
    opacity: 1;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);

    font-size: 20px;
  }

  #cta a
  {
    padding: 25px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

table
{
  width: 100%;
  border-collapse: collapse;

  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
}

table span
{
  display: block;

  font-family: 'GT Pressura Regular', sans-serif;
}

table thead tr td:nth-child(2),
table thead tr td:nth-child(3)
{
  padding-left: 2vw;
  white-space: nowrap;
}

table thead tr td,
table tbody tr td
{
  padding-bottom: 2vw;
  border-bottom: 1px solid #334854;
}

table thead tr td
{
  border-bottom: 5px solid #334854;
}

table tbody tr td
{
  padding-top: 2vw;
}

table tbody tr:last-child td
{
  padding-bottom: 0;
  border-bottom: none;
}

table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3)
{
  text-align: center;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.Badge:hover
{
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes SimpleBadgeSwing
{
    0%{-webkit-transform:rotate(-2deg) scale(0.9);transform:rotate(-2deg) scale(0.9)}
    50%{-webkit-transform:rotate(2deg) scale(0.9);transform:rotate(2deg) scale(0.9)}
    100%{-webkit-transform:rotate(-2deg) scale(0.9);transform:rotate(-2deg) scale(0.9)}
}

@keyframes SimpleBadgeSwing
{
    0%{-webkit-transform:rotate(-2deg) scale(0.9);transform:rotate(-2deg) scale(0.9)}
    50%{-webkit-transform:rotate(2deg) scale(0.9);transform:rotate(2deg) scale(0.9)}
    100%{-webkit-transform:rotate(-2deg) scale(0.9);transform:rotate(-2deg) scale(0.9)}
}

.SimpleBadge
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.SimpleBadge.Badge--animated
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
    -webkit-animation: SimpleBadgeSwing 3s infinite ease-in-out;
            animation: SimpleBadgeSwing 3s infinite ease-in-out;
    -webkit-transform-origin: top;
            transform-origin: top;
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.SimpleBadge .Badge__FirstFillColor
{
    fill: #ff2e2e;
}
.SimpleBadge .Badge__FirstStrokeColor
{
    stroke: #ff2e2e;
}

.SimpleBadge .Badge__SecondFillColor
{
    fill: #ffffff;
}

.SimpleBadge .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.SimpleBadge .Badge__ThirdFillColor
{
    fill: #dd2222;
}

.SimpleBadge .Badge__ThirdStrokeColor
{
    stroke: #dd2222;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_1
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_1:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_1 .Badge__FirstFillColor
{
    fill: #3e5266;
}
.RadialBadge_1 .Badge__FirstStrokeColor
{
    stroke: #3e5266;
}

.RadialBadge_1 .Badge__SecondFillColor
{
    fill: #a38671;
}

.RadialBadge_1 .Badge__SecondStrokeColor
{
    stroke: #a38671;
}

.RadialBadge_1 .Badge__ThirdFillColor
{
    fill: #f9f9f9;
}

.RadialBadge_1 .Badge__ThirdStrokeColor
{
    stroke: #f9f9f9;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_2_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_2_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_2.Badge--animated .RADIAL_LINE_DOTS
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
    -webkit-animation: RadialBadge_2_Animation 40s linear infinite;
            animation: RadialBadge_2_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_2
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_2:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_2 .Badge__FirstFillColor
{
    fill: #3e5266;
}
.RadialBadge_2 .Badge__FirstStrokeColor
{
    stroke: #3e5266;
}

.RadialBadge_2 .Badge__SecondFillColor
{
    fill: #59d9e4;
}

.RadialBadge_2 .Badge__SecondStrokeColor
{
    stroke: #59d9e4;
}

.RadialBadge_2 .Badge__ThirdFillColor
{
    fill: #d6e0e8;
}

.RadialBadge_2 .Badge__ThirdStrokeColor
{
    stroke: #d6e0e8;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_3_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_3_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_3.Badge--animated #TEETH
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
    -webkit-animation: RadialBadge_3_Animation 40s linear infinite;
            animation: RadialBadge_3_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_3
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_3:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_3 .Badge__FirstFillColor
{
    fill: #fcef4f;
}
.RadialBadge_3 .Badge__FirstStrokeColor
{
    stroke: #fcef4f;
}

.RadialBadge_3 .Badge__SecondFillColor
{
    fill: #4bdcd6;
}

.RadialBadge_3 .Badge__SecondStrokeColor
{
    stroke: #4bdcd6;
}

.RadialBadge_3 .Badge__ThirdFillColor
{
    fill: #f674b3;
}

.RadialBadge_3 .Badge__ThirdStrokeColor
{
    stroke: #f674b3;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_4_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_4_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_4.Badge--animated .PIZZA
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
    -webkit-animation: RadialBadge_4_Animation 40s linear infinite;
            animation: RadialBadge_4_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_4
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_4:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_4 .Badge__FirstFillColor
{
    fill: #f97b7e;
}
.RadialBadge_4 .Badge__FirstStrokeColor
{
    stroke: #f97b7e;
}

.RadialBadge_4 .Badge__SecondFillColor
{
    fill: #4d4d4d;
}

.RadialBadge_4 .Badge__SecondStrokeColor
{
    stroke: #4d4d4d;
}

.RadialBadge_4 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_4 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_5_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_5_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_5.Badge--animated .OUTLINE
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
    -webkit-animation: RadialBadge_5_Animation 40s linear infinite;
            animation: RadialBadge_5_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_5
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_5:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_5 .Badge__FirstFillColor
{
    fill: #ffffff;
}
.RadialBadge_5 .Badge__FirstStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_5 .Badge__SecondFillColor
{
    fill: #2952b7;
}

.RadialBadge_5 .Badge__SecondStrokeColor
{
    stroke: #2952b7;
}

.RadialBadge_5 .Badge__ThirdFillColor
{
    fill: #d6e0e8;
}

.RadialBadge_5 .Badge__ThirdStrokeColor
{
    stroke: #d6e0e8;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_6
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_6:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_6 .Badge__FirstFillColor
{
    fill: #161516;
}
.RadialBadge_6 .Badge__FirstStrokeColor
{
    stroke: #161516;
}

.RadialBadge_6 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.RadialBadge_6 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_6 .Badge__ThirdFillColor
{
    fill: #ccc;
}

.RadialBadge_6 .Badge__ThirdStrokeColor
{
    stroke: #ccc;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_7_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_7_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_7.Badge--animated .STRIPES
{
    -webkit-animation: RadialBadge_7_Animation 40s linear infinite;
            animation: RadialBadge_7_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_7.Badge--animated
{
    -webkit-transition: .2s transform ease-out;
    transition: .2s transform ease-out;
}

.RadialBadge_7
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_7:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_7 .Badge__FirstFillColor
{
    fill: #e386b3;
}
.RadialBadge_7 .Badge__FirstStrokeColor
{
    stroke: #e386b3;
}

.RadialBadge_7 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.RadialBadge_7 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_7 .Badge__ThirdFillColor
{
    fill: #ccc;
}

.RadialBadge_7 .Badge__ThirdStrokeColor
{
    stroke: #ccc;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_8
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_8:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_8 .Badge__FirstFillColor
{
    fill: #bbecf8;
}
.RadialBadge_8 .Badge__FirstStrokeColor
{
    stroke: #bbecf8;
}

.RadialBadge_8 .Badge__SecondFillColor
{
    fill: #0a8aae;
}

.RadialBadge_8 .Badge__SecondStrokeColor
{
    stroke: #0a8aae;
}

.RadialBadge_8 .Badge__ThirdFillColor
{
    fill: #d33068;
}

.RadialBadge_8 .Badge__ThirdStrokeColor
{
    stroke: #d33068;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_9_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_9_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_9.Badge--animated .DOTS
{
    -webkit-animation: RadialBadge_9_Animation 40s linear infinite;
            animation: RadialBadge_9_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_9.Badge--animated .DOTS2
{
    -webkit-animation: RadialBadge_9_Animation 60s linear infinite;
            animation: RadialBadge_9_Animation 60s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_9
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_9:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_9 .Badge__FirstFillColor
{
    fill: #000000;
}
.RadialBadge_9 .Badge__FirstStrokeColor
{
    stroke: #000000;
}

.RadialBadge_9 .Badge__SecondFillColor
{
    fill: #f1b41e;
}

.RadialBadge_9 .Badge__SecondStrokeColor
{
    stroke: #f1b41e;
}

.RadialBadge_9 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_9 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_10
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_10:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_10 .Badge__FirstFillColor
{
    fill: #ffeecc;
}
.RadialBadge_10 .Badge__FirstStrokeColor
{
    stroke: #ffeecc;
}

.RadialBadge_10 .Badge__SecondFillColor
{
    fill: #04393a;
}

.RadialBadge_10 .Badge__SecondStrokeColor
{
    stroke: #04393a;
}

.RadialBadge_10 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_10 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_11
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_11:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_11 .Badge__FirstFillColor
{
    fill: #373e44;
}
.RadialBadge_11 .Badge__FirstStrokeColor
{
    stroke: #373e44;
}

.RadialBadge_11 .Badge__SecondFillColor
{
    fill: #ff5c36;
}

.RadialBadge_11 .Badge__SecondStrokeColor
{
    stroke: #ff5c36;
}

.RadialBadge_11 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_11 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_12_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_12_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_12.Badge--animated .DOTS
{
    -webkit-animation: RadialBadge_12_Animation 40s linear infinite;
            animation: RadialBadge_12_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_12.Badge--animated .DOTS2
{
    -webkit-animation: RadialBadge_12_Animation 60s linear infinite;
            animation: RadialBadge_12_Animation 60s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_12
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_12:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_12 .Badge__FirstFillColor
{
    fill: #32425b;
}
.RadialBadge_12 .Badge__FirstStrokeColor
{
    stroke: #32425b;
}

.RadialBadge_12 .Badge__SecondFillColor
{
    fill: #b34f4f;
}

.RadialBadge_12 .Badge__SecondStrokeColor
{
    stroke: #b34f4f;
}

.RadialBadge_12 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_12 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_13_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_13_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_13.Badge--animated .DOTS
{
    -webkit-animation: RadialBadge_13_Animation 40s linear infinite;
            animation: RadialBadge_13_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_13.Badge--animated .DOTS2
{
    -webkit-animation: RadialBadge_13_Animation 60s linear infinite;
            animation: RadialBadge_13_Animation 60s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_13
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_13:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_13 .Badge__FirstFillColor
{
    fill: #ffe000;
}
.RadialBadge_13 .Badge__FirstStrokeColor
{
    stroke: #06ffbe;
}

.RadialBadge_13 .Badge__SecondFillColor
{
    fill: #18243e;
}

.RadialBadge_13 .Badge__SecondStrokeColor
{
    stroke: #18243e;
}

.RadialBadge_13 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_13 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes RadialBadge_14_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes RadialBadge_14_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_14.Badge--animated .DOTS
{
    -webkit-animation: RadialBadge_14_Animation 40s linear infinite;
            animation: RadialBadge_14_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_14.Badge--animated .DOTS2
{
    -webkit-animation: RadialBadge_14_Animation 60s linear infinite;
            animation: RadialBadge_14_Animation 60s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.RadialBadge_14
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.RadialBadge_14:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_14 .Badge__FirstFillColor
{
    fill: #ff524f;
}
.RadialBadge_14 .Badge__FirstStrokeColor
{
    stroke: #ff524f;
}

.RadialBadge_14 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.RadialBadge_14 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.RadialBadge_14 .Badge__ThirdFillColor
{
    fill: #ffffff;
}

.RadialBadge_14 .Badge__ThirdStrokeColor
{
    stroke: #ffffff;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@-webkit-keyframes BurgerBadge_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes BurgerBadge_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.BurgerBadge.Badge--animated .SUNBURST
{
    -webkit-animation: BurgerBadge_Animation 40s linear infinite;
            animation: BurgerBadge_Animation 40s linear infinite;
    -webkit-transform-origin: center;
            transform-origin: center;
}

.BurgerBadge
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-transition: .2s transform ease-out;

    transition: .2s transform ease-out;
}

.BurgerBadge:hover
{
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BurgerBadge .Badge__FirstFillColor
{
    fill: #f9d974;
}
.BurgerBadge .Badge__FirstStrokeColor
{
    stroke: #f9d974;
}

.BurgerBadge .Badge__SecondFillColor
{
    fill: #af202d;
}

.BurgerBadge .Badge__SecondStrokeColor
{
    stroke: #af202d;
}

.BurgerBadge .Badge__ThirdFillColor
{
    fill: #e02e31;
}

.BurgerBadge .Badge__ThirdStrokeColor
{
    stroke: #e02e31;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_1
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_1 .Badge__FirstFillColor
{
    fill: #000000;
}
.BlankBadge_1 .Badge__FirstStrokeColor
{
    stroke: #000000;
}

.BlankBadge_1 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.BlankBadge_1 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.BlankBadge_1 .Badge__ThirdFillColor
{
    fill: #dd2222;
}

.BlankBadge_1 .Badge__ThirdStrokeColor
{
    stroke: #dd2222;
}
/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_2
{
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.BlankBadge_2 .Badge__FirstFillColor
{
    fill: #000000;
}
.BlankBadge_2 .Badge__FirstStrokeColor
{
    stroke: #000000;
}

.BlankBadge_2 .Badge__SecondFillColor
{
    fill: #ffffff;
}

.BlankBadge_2 .Badge__SecondStrokeColor
{
    stroke: #ffffff;
}

.BlankBadge_2 .Badge__ThirdFillColor
{
    fill: #dd2222;
}

.BlankBadge_2 .Badge__ThirdStrokeColor
{
    stroke: #dd2222;
}
