/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_1
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_1:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_1 .Badge__FirstFillColor
{
    fill: #3e5266;
}
.RadialBadge_1 .Badge__FirstStrokeColor
{
    stroke: #3e5266;
}

.RadialBadge_1 .Badge__SecondFillColor
{
    fill: #a38671;
}

.RadialBadge_1 .Badge__SecondStrokeColor
{
    stroke: #a38671;
}

.RadialBadge_1 .Badge__ThirdFillColor
{
    fill: #f9f9f9;
}

.RadialBadge_1 .Badge__ThirdStrokeColor
{
    stroke: #f9f9f9;
}