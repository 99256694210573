@font-face
{
  font-family: 'GT Pressura Regular';
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face
{
  font-family: 'GT Pressura Bold';
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('/assets/fonts/GT_Pressura/GT-Pressura-Mono-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* ------------------------------------------------------------------------------------------------------------------ */

*
{
  margin: 0;
  padding: 0;
}

img
{
  width: 100%;
  height: auto;
}

.logo
{
  margin: 0 auto 2vw auto;
  max-width: 100px;
}

.wix
{
  display: inline-block;
  max-width: 5vw;
}

strong
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
}

@media only screen and (max-width: 900px)
{
  .wix
  {
    max-width: 7.5vw;
  }

  .hide_on_mobile
  {
    display: none !important;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#about,
#benefits,
#testimonials,
#features,
#faq,
#marketplace,
#footer,
#cta
{
  font-family: 'GT Pressura Regular', sans-serif;
  font-weight: normal;
  font-size: 1.8vw;
  line-height: 2.2vw;
  color: #334854;
}

@media only screen and (max-width: 900px)
{
  #about,
  #benefits,
  #testimonials,
  #features,
  #faq,
  #marketplace,
  #footer,
  #cta
  {
    font-size: 16px;
    line-height: 22px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

h1,
h4
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 11vw;
  line-height: 9vw;
  text-transform: uppercase;
}

h1 span,
h4 span
{
  display: block;

  font-family: 'GT Pressura Regular', sans-serif;
  font-size: 2vw;
  line-height: 5vw;
}

h2
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 1.8vw;/*2vw;*/
  line-height: 3vw;
  text-transform: uppercase;
}

h3
{
  font-size: 3vw;
  line-height: 3.4vw;
}

@media only screen and (max-width: 900px)
{
  h1,
  h4
  {
    font-size: 20vw;
    line-height: 16vw;
    text-align: center;
  }

  h1 span,
  h4 span
  {
    font-size: 3.8vw;
    line-height: 9vw;
  }

  h2
  {
    font-size: 25px;
    line-height: 30px;
  }

  h3
  {
    margin-right: auto;
    margin-left: auto;
    width: 80%;

    font-size: 25px;
    line-height: 30px;
  }

  h3 br
  {
    display: none;
  }
}

@media only screen and (max-width: 900px)
{
  h3
  {
    width: 100%;
  }
}

p a
{
  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: #334854;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.columns
{
  position: relative;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/* ---------------------- */

.columns.w20 .column
{
  margin-top: 5vw;
  width: 20%;

  text-align: center;
}

.columns.w20 .column:nth-child(1),
.columns.w20 .column:nth-child(2),
.columns.w20 .column:nth-child(3),
.columns.w20 .column:nth-child(4),
.columns.w20 .column:nth-child(5)
{
  margin-top: 0;
}

.columns.w20 .column .inner
{
  margin-right: 3vw;
  margin-left: 3vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w20 .column
  {

  }

  .columns.w20 .column:nth-child(1),
  .columns.w20 .column:nth-child(2),
  .columns.w20 .column:nth-child(3),
  .columns.w20 .column:nth-child(4),
  .columns.w20 .column:nth-child(5)
  {

  }

  .columns.w20 .column .inner
  {

  }
}

/* ---------------------- */

.columns.w25 .column
{
  margin-top: 5vw;
  width: 25%;

  text-align: center;
}

.columns.w25 .column:nth-child(1),
.columns.w25 .column:nth-child(2),
.columns.w25 .column:nth-child(3),
.columns.w25 .column:nth-child(4)
{
  margin-top: 0;
}

.columns.w25 .column .inner
{
  margin-right: 3vw;
  margin-left: 3vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w25 .column
  {
    margin-top: 50px;
    width: 33.33%;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3),
  .columns.w25 .column:nth-child(4)
  {
    margin-top: 50px;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3)
  {
    margin-top: 0;
  }

  .columns.w25 .column .inner
  {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 700px)
{
  .columns.w25 .column
  {
    margin-top: 30px;
    width: 50%;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2),
  .columns.w25 .column:nth-child(3),
  .columns.w25 .column:nth-child(4)
  {
    margin-top: 30px;
  }

  .columns.w25 .column:nth-child(1),
  .columns.w25 .column:nth-child(2)
  {
    margin-top: 0;
  }
}

/* ---------------------- */

.columns.w50 .column
{
  width: 50%;
}

@media only screen and (max-width: 900px)
{
  .columns.w50 .column
  {
    width: 100%;
  }

  .columns.w50 .column.right
  {
    margin-top: 5vw;
  }
}

/* ---------------------- */

.columns.w33 .column
{
  margin-top: 2vw;
  width: 33.33%;
}

.columns.w33 .column .inner
{
  padding-top: 2vw;
  border-top: 1px solid #334854;
}

.columns.w33 .column:nth-child(1),
.columns.w33 .column:nth-child(2),
.columns.w33 .column:nth-child(3)
{
  margin-top: 0;
}

.columns.w33 .column:nth-child(3n+1) .inner
{
  margin-right: 2vw;
}

.columns.w33 .column:nth-child(3n+2) .inner
{
  margin-right: 1vw;
  margin-left: 1vw;
}

.columns.w33 .column:nth-child(3n+3) .inner
{
  margin-left: 2vw;
}

@media only screen and (max-width: 900px)
{
  .columns.w33 .column
  {
    margin-top: 20px;
    width: 50%;
  }

  .columns.w33 .column .inner
  {
    padding-top: 20px;
  }

  .columns.w33 .column:nth-child(3)
  {
    margin-top: 20px;
  }

  .columns.w33 .column:nth-child(3n+1) .inner,
  .columns.w33 .column:nth-child(3n+2) .inner,
  .columns.w33 .column:nth-child(3n+3) .inner
  {
    margin: 0;
  }

  .columns.w33 .column:nth-child(2n+1) .inner
  {
    margin-right: 20px;
  }

  .columns.w33 .column:nth-child(2n+2) .inner
  {
    margin-right: 0;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 700px)
{
  .columns.w33 .column
  {
    margin-top: 20px;
    width: 100%;
  }

  .columns.w33 .column .inner
  {
    padding-top: 20px;
  }

  .columns.w33 .column:nth-child(1),
  .columns.w33 .column:nth-child(2),
  .columns.w33 .column:nth-child(3)
  {
    margin-top: 20px;
  }

  .columns.w33 .column:nth-child(3n+1) .inner,
  .columns.w33 .column:nth-child(3n+2) .inner,
  .columns.w33 .column:nth-child(3n+3) .inner
  {
    margin: 0;
  }

  .columns.w33 .column:nth-child(2n+1) .inner
  {
    margin-right: 0;
  }

  .columns.w33 .column:nth-child(2n+2) .inner
  {
    margin-right: 0;
    margin-left: 0;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

.inside
{
  position: relative;
  padding: 7vw 18vw;
}

.icon
{
  font-size: 10vw;
  line-height: 5vw;
}

@media only screen and (max-width: 900px)
{
  .inside
  {
    padding: 10vw;
  }

  .icon
  {
    font-size: 10vw;
    line-height: 5vw;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

@keyframes SimpleBadgeSwingWebsite
{
  0% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }

  50% {
    -webkit-transform: rotate(2deg) scale(0.9);
    transform: rotate(2deg) scale(0.9);
  }

  100% {
    -webkit-transform: rotate(-2deg) scale(0.9);
    transform: rotate(-2deg) scale(0.9);
  }
}

#staticbadge
{
  cursor: pointer;
  z-index: 0;
  position: absolute;
  bottom: 3vw;
  right: 5vw;
  width: 16vw;

  -webkit-transition: .2s transform ease-out;
  transition: .2s transform ease-out;

  -webkit-animation: SimpleBadgeSwingWebsite 3s infinite ease-in-out;
  animation: SimpleBadgeSwingWebsite 3s infinite ease-in-out;

  -webkit-transform-origin: top;
  transform-origin: top;
}

@media only screen and (max-width: 900px)
{
  #staticbadge
  {
    display: none;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#about
{
  background-color: #faf85d;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#video
{
  background-color: #faf85d;

  line-height: 0;
}

#video video
{
  width: 100%;
  height: auto;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#benefits
{
  background-color: #faf85d;
}

#benefits .headline
{
  margin-bottom: 5vw;

  text-align: center;
}

@media only screen and (max-width: 900px)
{
  #benefits .headline
  {
    margin-bottom: 30px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#badges
{
  background-color: #faf85d;
}

#badges .inside
{
  padding: 3vw;
  margin: 0 6vw;
  background-color: #d1dfe1;
}

#badges .border
{
  padding: 6vw;
  border: 1px solid #334854;
}

#badges .headline
{
  margin-bottom: 5vw;
  text-align: center;
}

#badges .headline h3
{
  margin-bottom: 2vw;
  font-family: 'GT Pressura Regular', sans-serif;
  color: #334854;
}

@media only screen and (max-width: 900px)
{
 #badges .inside
 {
   margin: 0;
 }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#testimonials
{
  background-color: #faf85d;

  text-align: center;
}

#testimonials h3
{
  margin-bottom: 2vw;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#features
{
  background-color: #faf85d;
}

#features .inside
{
  padding-top: 0;
}

@media only screen and (max-width: 900px)
{
  #features
  {
    display: none;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#faq
{
  background-color: #334854;

  color: #d1dfe1;
}

#faq .headline
{
  margin-bottom: 5vw;

  text-align: center;
}

#faq strong
{
  display: block;
  margin-bottom: 0.5vw;

  color: #ffffff;
}

#faq .item
{
  padding: 2vw 0;
  border-bottom: 1px solid #d1dfe1;
}

#faq .item:nth-child(2)
{
  padding-top: 0;
}

#faq .item:last-child
{
  padding-bottom: 0;
  border: none;
}

@media only screen and (max-width: 900px)
{
  #faq .headline
  {
    margin-bottom: 30px;
  }

  #faq strong
  {
    margin-bottom: 10px;
  }

  #faq .item
  {
    padding: 20px 0;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#marketplace
{
  background-color: #d1dfe1;/*#00c638;*/
}

#marketplace .inside
{
  padding-bottom: 14vw;
}

@media only screen and (max-width: 900px)
{
  #marketplace .inside
  {
    padding-bottom: 30vw;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

#footer
{
  display: none;
  background-color: #334854;

  font-size: 1vw;
  line-height: 1vw;
  color: #d1dfe1;
}

#footer .inside
{
  padding: 2.5vw 3vw;
  padding-bottom: 8.5vw;
}

#footer p a
{
  font-family: 'GT Pressura Regular', sans-serif;
  text-transform: none;
  color: #d1dfe1;
}

#footer .column.right
{
  text-align: right;
}

/* ------------------------------------------------------------------------------------------------------------------ */

#cta
{
  cursor: pointer;
  z-index: 10000;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #00c638;

  opacity: .95;

  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
  font-size: 3vw;
  text-transform: uppercase;
  text-align: center;
  color: #005117;
}

#cta a
{
  display: block;
  padding: 3vw 2vw;

  text-decoration: none;
  color: #005117;
}

@media only screen and (min-width: 1100px)
{
  #cta,
  #cta a
  {
    transition: all .2s ease-out;
  }

  #cta:hover
  {
    opacity: 1;
  }

  #cta a:hover
  {
    padding: 4vw 2vw;
  }
}

@media only screen and (max-width: 900px)
{
  #cta
  {
    opacity: 1;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);

    font-size: 20px;
  }

  #cta a
  {
    padding: 25px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */

table
{
  width: 100%;
  border-collapse: collapse;

  font-family: 'GT Pressura Bold', sans-serif;
  font-weight: normal;
}

table span
{
  display: block;

  font-family: 'GT Pressura Regular', sans-serif;
}

table thead tr td:nth-child(2),
table thead tr td:nth-child(3)
{
  padding-left: 2vw;
  white-space: nowrap;
}

table thead tr td,
table tbody tr td
{
  padding-bottom: 2vw;
  border-bottom: 1px solid #334854;
}

table thead tr td
{
  border-bottom: 5px solid #334854;
}

table tbody tr td
{
  padding-top: 2vw;
}

table tbody tr:last-child td
{
  padding-bottom: 0;
  border-bottom: none;
}

table tbody tr td:nth-child(2),
table tbody tr td:nth-child(3)
{
  text-align: center;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.Badge:hover
{
  transform: scale(1) !important;
}