/*
 * BASICS
 * ---------------------------------------------------------------------------------------------------------------------
 */

@keyframes RadialBadge_3_Animation
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.RadialBadge_3.Badge--animated #TEETH
{
    transition: .2s transform ease-out;
    animation: RadialBadge_3_Animation 40s linear infinite;
    transform-origin: center;
}

.RadialBadge_3
{
    user-select: none;

    transition: .2s transform ease-out;
}

.RadialBadge_3:hover
{
    transform: scale(0.97);
}

/*
 * COLORS
 * ---------------------------------------------------------------------------------------------------------------------
 */

.RadialBadge_3 .Badge__FirstFillColor
{
    fill: #fcef4f;
}
.RadialBadge_3 .Badge__FirstStrokeColor
{
    stroke: #fcef4f;
}

.RadialBadge_3 .Badge__SecondFillColor
{
    fill: #4bdcd6;
}

.RadialBadge_3 .Badge__SecondStrokeColor
{
    stroke: #4bdcd6;
}

.RadialBadge_3 .Badge__ThirdFillColor
{
    fill: #f674b3;
}

.RadialBadge_3 .Badge__ThirdStrokeColor
{
    stroke: #f674b3;
}